// ProductList.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import db from '../firebaseConfig';
import { collection, getDocs, query, where, doc, deleteDoc } from 'firebase/firestore';
import '../styles.css';
// Removed debounce import as it's no longer needed

function ProductList() {
  const { country } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showAddProductPrompt, setShowAddProductPrompt] = useState(false);

  // Fetch search term from URL on initial load or when URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const term = params.get('search') || '';
    if (term) {
      const upperCaseTerm = term.toUpperCase();
      setSearchTerm(upperCaseTerm);
      handleSearch(upperCaseTerm);
    } else {
      setFilteredProducts([]);
      setShowAddProductPrompt(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, location.search]);

  const handleSearch = async (term = searchTerm) => {
    const upperCaseTerm = term.toUpperCase(); // Convert search term to uppercase
    if (!upperCaseTerm.trim()) {
      setFilteredProducts([]);
      setShowAddProductPrompt(false);
      return;
    }

    const productsCol = collection(db, country === 'korea' ? 'koreanProducts' : 'thaiProducts');

    // Construct multiple queries to simulate fuzzy search
    const queriesArray = [
      // Search itemNumber contains term
      query(productsCol, where('itemNumber', '>=', upperCaseTerm), where('itemNumber', '<=', upperCaseTerm + '\uf8ff')),

      // Search name contains term
      query(productsCol, where('name', '>=', upperCaseTerm), where('name', '<=', upperCaseTerm + '\uf8ff')),

      // Search styleBarcodes contains term
      query(productsCol, where('styleBarcodes', 'array-contains', upperCaseTerm)),

      // Search styles contains term
      query(productsCol, where('styles', 'array-contains', upperCaseTerm)),
    ];

    try {
      // Execute all queries
      const querySnapshots = await Promise.all(queriesArray.map(q => getDocs(q)));

      // Merge results to avoid duplicates
      const productMap = {};
      querySnapshots.forEach(snapshot => {
        snapshot.forEach(doc => {
          productMap[doc.id] = { id: doc.id, ...doc.data() };
        });
      });

      const mergedResults = Object.values(productMap);

      // Further filter on the client for fuzzy search
      const filtered = mergedResults.filter(p =>
        (p.styleBarcodes && p.styleBarcodes.some(barcode => typeof barcode === 'string' && barcode.toUpperCase().includes(upperCaseTerm))) ||
        (p.itemNumber && p.itemNumber.toUpperCase().includes(upperCaseTerm)) ||
        (p.name && p.name.toUpperCase().includes(upperCaseTerm)) ||
        (p.styles && p.styles.some(style => style.toUpperCase().includes(upperCaseTerm)))
      );

      // Sort by product name
      filtered.sort((a, b) => a.name.localeCompare(b.name));

      setFilteredProducts(filtered);

      if (filtered.length === 0) {
        setShowAddProductPrompt(true);
      } else {
        setShowAddProductPrompt(false);
      }
    } catch (error) {
      console.error("搜尋失敗:", error);
    }
  };

  // Removed debounce logic
  const handleChange = (e) => {
    setSearchTerm(e.target.value.toUpperCase()); // Convert input to uppercase
    // Removed debouncedSearch call
  };

  const handleAddProduct = () => {
    navigate(`/add/${country}?barcode=${encodeURIComponent(searchTerm)}&search=${encodeURIComponent(searchTerm)}`);
  };

  const handleSelectProduct = (id, e) => {
    e.stopPropagation();
    setSelectedProducts(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(item => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleDeleteSelected = async () => {
    if (!window.confirm('你確定要刪除選擇的商品嗎？這個動作不能撤銷。')) {
      return;
    }
    const batch = selectedProducts.map(async (id) => {
      const productRef = doc(db, country === 'korea' ? 'koreanProducts' : 'thaiProducts', id);
      await deleteDoc(productRef);
    });

    try {
      await Promise.all(batch);
      setSelectedProducts([]);
      // Re-execute search to update the list
      handleSearch();
    } catch (error) {
      console.error("刪除失敗:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // Update URL query parameter and execute search
      const upperCaseTerm = searchTerm.toUpperCase();
      navigate(`?search=${encodeURIComponent(upperCaseTerm)}`);
      handleSearch(upperCaseTerm);
    }
  };

  const handleSearchClick = () => {
    // Update URL query parameter and execute search
    const upperCaseTerm = searchTerm.toUpperCase();
    navigate(`?search=${encodeURIComponent(upperCaseTerm)}`);
    handleSearch(upperCaseTerm);
  };

  return (
    <div className="container">
      <h2>商品列表</h2>
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={(e) => e.target.select()} // Select all text on focus
        placeholder="輸入商品條碼、料號或品名"
      />
      <button onClick={handleSearchClick}>搜尋</button>
      {showAddProductPrompt && (
        <div>
          <p>未匹配到商品，是否新增商品？</p>
          <button onClick={handleAddProduct}>新增商品</button>
        </div>
      )}
      {filteredProducts.length > 0 && (
        <>
          <button onClick={handleDeleteSelected} disabled={selectedProducts.length === 0}>刪除選擇的商品</button>
          <div className="product-list">
            {filteredProducts.map(product => (
              <div
                key={product.id}
                className="product-card"
                onClick={() => navigate(`/view/${country}/${product.id}?search=${encodeURIComponent(searchTerm)}`)}
              >
                <div className="product-checkbox" onClick={(e) => e.stopPropagation()}>
                  <input
                    type="checkbox"
                    checked={selectedProducts.includes(product.id)}
                    onChange={(e) => handleSelectProduct(product.id, e)}
                  />
                </div>
                <img src={product.image} alt={product.name} className="product-image-small" />
                <div className="product-info">
                  <span>{product.name} ({product.styles.join(' / ')})</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ProductList;
